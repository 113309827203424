<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="55" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Inicio</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Funciones</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
          <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Caracteristicas</a>
        </li>
        <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'clients'}">
          <a [ngxScrollTo]="'#clients'" href="javascript: void(0);" class="nav-link">Clientes</a>
        </li> -->
        <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
          <a [ngxScrollTo]="'#pricing'" href="javascript: void(0);" class="nav-link">Planes</a>
        </li>
      </ul>
      <button class="btn btn-success btn-rounded navbar-btn" (click)="PruebaGratis()" style="color: #ffffff;background-color: #53ee90;">Prueba gratis</button>
      <button class="btn btn-primary btn-rounded navbar-btn" (click)="Acceder()" style="color: #ffffff;background: #00e3fc;border-color: #00e3fc;">Acceder</button>

    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!-- Hero section Start -->
  <section class="hero-section" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="hero-wrapper mb-4">
            <!-- <p class="font-16 text-uppercase">Empieza a facturar hoy</p> -->
            <h1 class="hero-title mb-4">Empieza a facturar en la nube con <span class="text-primary" style="font-family: Doppio One;">DomiSys </span>
            </h1>

            <p class="font-16">Empieza tu prueba gratis y descubre todas las características que te brindamos para tener el control de tu negocio desde cualquier lugar y dispositivo</p>

            <div class="row">

              <div class="mt-4 col-lg-5 col-sm-6">
                <a href="#" class="btn btn-success mt-2 mr-2 w-100" (click)="PruebaGratis()">Empieza gratis!</a>
              </div>
              <div class="mt-4 col-lg-5 col-sm-6">
                <a href="#" class="btn btn-primary mt-2 mr-2 w-100" (click)="TutorialRegistro()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play" viewBox="0 0 16 16">
                    <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"></path>
                  </svg>
                  ¿Cómo me registro?
                </a>
              </div>
            </div>
            
          </div>
        </div>

        <div class="col-lg-6 col-sm-8">
          <div class="home-img mt-5 mt-lg-0">
            <img src="assets/images/home-img.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->

  <app-services></app-services>
  <app-features></app-features>
  <!-- <app-clients></app-clients> -->
  <app-plans></app-plans>
  <app-footer></app-footer>
</div>
