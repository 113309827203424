<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="20" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
          <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Features</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'clients'}">
          <a [ngxScrollTo]="'#clients'" href="javascript: void(0);" class="nav-link">Clients</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
          <a [ngxScrollTo]="'#pricing'" href="javascript: void(0);" class="nav-link">Plans</a>
        </li>
      </ul>
      <button class="btn btn-success btn-rounded navbar-btn">Try for Free</button>

    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!-- Hero section Start -->
  <section class="hero-section" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="hero-wrapper mb-4">
            <p class="font-16 text-uppercase">Discover Invoza Today</p>
            <h1 class="hero-title mb-4">Make your Site Amazing & Unique with <span class="text-primary">Invoza</span>
            </h1>

            <p>To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine
              occidental</p>

            <div class="mt-4">
              <a href="javascript: void(0);" class="btn btn-primary mt-2 mr-2">Get Started</a>
              <a href="javascript: void(0);" class="btn btn-success mt-2 mr-2">Learn more</a>
            </div>
          </div>

        </div>

        <div class="col-lg-6 col-sm-8">
          <div class="home-img mt-5 mt-lg-0">
            <img src="assets/images/home-img.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->

  <app-services></app-services>
  <app-features></app-features>
  <app-clients></app-clients>
  <app-plans></app-plans>
  <app-footer></app-footer>
</div>
